import React, { Component, Fragment } from "react";
import axios from "axios";
// import { Button, Form } from "react-bootstrap";
import Helper from "../Global/Helper";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";
import ModalAlert from "../Components/ModalAlert";
import liffHelper from "../utils/liffHelper";
import messageHelper from "../utils/messagingApiHelper";
import logo_head from "./images/logo.png";
import appqr from "./images/appqr.png";
import Service from "../services/home";
import exit from "./images/exit.png";
import effect from "./images/effect.png";
import coupon from "./images/coupon.png";
import successpng from "./images/success.png";
import { geolocated } from "react-geolocated";

const liff = window.liff;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "home",
      // page: "condition",
      // page: "preview",
      // page: "success",
      openPolicy: false,
      isRegistered: false,
      submit_btn: true,
      code: "",
      first_name: "",
      jwt: null,
      last_name: "",
      mobile: "",
      isErrorCode: "",
      disableButton: true,
      msg: "",
      master_campaign_id: "22",
      policy_name: "2tm_policy",
      profile: {
        userId: "",
      },
      otp_number: "",
      disableOTPButton: true,
      isReQuestError: false,
      isVerifySuccess: false,
      isReQuestError_msg: "",
      data: {},
      otp_code: "",
      isReQuestError_head: "",
      ref_code: "",
      location: null,
      msg_error: "คุณใช้สิทธิลงทะเบียนไปแล้ว",
      minutes: 0,
      seconds: 5,
    };
  }

  componentDidMount() {
    // this.sendEasyMoneyGoldStep();
    this.actionStart();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState({
          location: position.coords.latitude + "," + position.coords.longitude,
        });
      },
      (error) => console.log(error)
    );
  }
  // ---------------------------SERVICE--------------------
  // LOAD JWT
  async actionStart() {
    try {
      const { data, status } = await Service.loadStartJWT();
      if (status === "Success") {
        if (data !== null) {
          this.setState({
            jwt: data,
          });
        } else {
          fetch(
            "https://api.telegram.org/bot1081008618:AAE5iEXu845qdrlhKsLJJjgMKrTeRLE6o0I/sendMessage?chat_id=-1001432601444&text=" +
              JSON.stringify({
                api: "loadStartJWT",
                jwt: data,
                uid: this.state.profile.userId || "",
                status: status,
              })
          );
          console.log("jwt not found");
          liff.closeWindow();
        }
      } else {
        fetch(
          "https://api.telegram.org/bot1081008618:AAE5iEXu845qdrlhKsLJJjgMKrTeRLE6o0I/sendMessage?chat_id=-1001432601444&text=" +
            JSON.stringify({
              api: "loadStartJWT",
              jwt: data || "",
              uid: this.state.profile.userId || "",
              status: status,
            })
        );
        liff.closeWindow();
        console.log("jwt NOT SUCCESS");
      }
    } catch (error) {
      fetch(
        "https://api.telegram.org/bot1081008618:AAE5iEXu845qdrlhKsLJJjgMKrTeRLE6o0I/sendMessage?chat_id=-1001432601444&text=" +
          JSON.stringify({
            api: "loadStartJWT",
            error,
          })
      );
      liff.closeWindow();
      console.log("DISCONNECT API");
    }
  }

  // ---------------------------END SERVICE--------------------

  submitHome = () => {
    this.setState({
      page: "condition",
    });
  };

  submitCondition = () => {
    this.setState({
      page: "preview",
    });
  };
  submitPreviewEdit = () => {
    this.setState({
      page: "home",
    });
  };

  submitPreviewNext = () => {
    this.insertDataCustomer();
    // this.sendEasyMoneyGoldStep1();
  };

  async insertDataCustomer() {
    const { history } = this.props;
    const { first_name, location, mobile, last_name, jwt } = this.state;
    let phone_number = mobile || "";
    let email = "";
    let locations = location || [0, 0];
    let promotion_code = "";
    let master_campaign_id = "22";
    let obj_location_detail = "";
    let otp_code = "";
    let otp_ref_code = "";
    let uidLine = this.state.profile.userId || "";
    try {
      const { status, msg, data } = await Service.insertDataCustomer(
        jwt,
        first_name,
        last_name,
        phone_number,
        email,
        locations,
        promotion_code,
        master_campaign_id,
        obj_location_detail,
        otp_code,
        otp_ref_code,
        uidLine
      );
      const { exp } = data;
      if (status === "Success") {
        // console.log(status);
        this.setState(
          {
            page: "success",
          },
          () => {
            this.setEasyStep();
          }
        );
      } else {
        this.setState({
          isRegistered: true,
          msg_error: msg,
        });
      }
    } catch (error) {
      // console.log(error);
    }
  }
  onVerifySuccess = (exp) => {
    this.setState(
      {
        isVerifySuccess: true,
      },
      () => {
        this.myInterval = setInterval(() => {
          const { seconds, minutes } = this.state;

          if (seconds > 0) {
            this.setState(({ seconds }) => ({
              seconds: seconds - 1,
            }));
          }
          if (seconds === 0) {
            clearInterval(this.myInterval);
            console.log("Success Send Coupon");
          }
        }, 1000);
        setTimeout(() => {
          // this.sendEasyMoney(exp);
          this.sendEasyMoneyNoPolicy();
        }, 5000);
        setTimeout(() => {
          liff.closeWindow();
        }, 5500);
      }
    );
  };

  setEasyStep() {
    setTimeout(() => {
      this.sendEasyMoneyGoldStep1();
    }, 100);
    // setTimeout(() => {
    //   liff.closeWindow();
    //   // this.sendEasyMoneyGoldStep2();
    // }, 800);
    // setTimeout(() => {
    //   this.sendEasyMoneyGoldStep3();
    // }, 1500);
    setTimeout(() => {
      liff.closeWindow();
    }, 7000);
  }

  sendEasyMoneyGoldStep1() {
    liff.sendMessages([
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url: "https://easymoneydev.com/gold_thunyaburee/Gcouponthun01.png",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
          body: {
            type: "box",
            layout: "vertical",
            backgroundColor: "#FFFFFF",
            contents: [
              {
                type: "text",
                text: "รีไฟแนนซ์ รับอัตราดอกเบี้ 0.99% 2 เดือนแรก",
                size: "xxs",
                flex: 1,
                contents: [],
              },
              {
                type: "text",
                text: "หมดเขต 31 สิงหาคม 2564",
                size: "xxs",
                flex: 1,
                contents: [],
              },
            ],
          },
        },
      },
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url: "https://easymoneydev.com/gold_thunyaburee/Gcouponthun02.png",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
          body: {
            type: "box",
            layout: "vertical",
            backgroundColor: "#FFFFFF",
            contents: [
              {
                type: "text",
                text: "ขายทองคำ รับเงินเพิ่มทันที บาทละ 100 บาท",
                size: "xxs",
                flex: 1,
                contents: [],
              },
              {
                type: "text",
                text: "หมดเขต 31 สิงหาคม 2564",
                size: "xxs",
                flex: 1,
                contents: [],
              },
            ],
          },
        },
      },
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url: "https://easymoneydev.com/gold_thunyaburee/Gcouponthun03.png",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
          body: {
            type: "box",
            layout: "vertical",
            backgroundColor: "#FFFFFF",
            contents: [
              {
                type: "text",
                text: "ฟรีค่ากำเหน็จ 1 สิทธิ์ / 5 ชิ้น ชำระเงินสดเท่านั้น",
                size: "xxs",
                flex: 1,
                contents: [],
              },
              {
                type: "text",
                text: "หมดเขต 31 พฤษภาคม 2564",
                size: "xxs",
                flex: 1,
                contents: [],
              },
            ],
          },
        },
      },
      {
        type: "flex",
        altText: "Flex Message",
        contents: {
          type: "bubble",
          hero: {
            type: "image",
            url: "https://easymoneydev.com/gold_thunyaburee/Gcouponthun04.png",
            margin: "none",
            size: "full",
            aspectRatio: "1:1",
            aspectMode: "cover",
            backgroundColor: "#FFFFFF",
          },
          body: {
            type: "box",
            layout: "vertical",
            backgroundColor: "#FFFFFF",
            contents: [
              {
                type: "text",
                text: "ซื้อเครื่องประดับเพชร ลดราคาจากป้าย 15%",
                size: "xxs",
                flex: 1,
                contents: [],
              },
              {
                type: "text",
                text: "หมดเขต 31 สิงหาคม 2564",
                size: "xxs",
                flex: 1,
                contents: [],
              },
            ],
          },
        },
      },
    ]);
  }

  render() {
    const {
      page,
      msg_error,
      isRegistered,
      first_name,
      last_name,
      mobile,
      submit_btn,
    } = this.state;
    return (
      <Fragment>
        <>
          <body>
            <header>
              <img src={logo_head} alt="" />
            </header>
            <section>
              {isRegistered === true ? (
                <div className="samllpop">
                  <div className="samllpop_pop">
                    <img
                      src={exit}
                      onClick={() => {
                        liff.closeWindow();
                      }}
                      alt=""
                      className="exit"
                    />
                    <h2 className="font55">ขออภัยค่ะ</h2>
                    <h3 className="font45">{msg_error}</h3>
                  </div>
                </div>
              ) : null}
              {page === "home" ? (
                <>
                  <div class="datahead">
                    <h1>สิทธิพิเศษ</h1>
                    <h3>เฉพาะลูกค้าลงทะเบียนเท่านั้น</h3>
                    <h4>ถึง 31 พฤษภาคม 2564</h4>
                  </div>
                  <div class="appqr">
                    <img src={appqr} alt="" />
                  </div>
                  <div class="dataform">
                    <div class="form-group">
                      <span class="group-name">ชื่อ</span>
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        class="form-control"
                        value={first_name}
                        onChange={(e) => {
                          let replaceInput = e.currentTarget.value.replace(
                            /[!$%^*()+></?=\;#]/g,
                            ""
                          );
                          if (
                            replaceInput !== "" &&
                            last_name.length > 0 &&
                            mobile.length === 10
                          ) {
                            this.setState({
                              first_name: replaceInput,
                              submit_btn: false,
                            });
                          } else {
                            this.setState({
                              first_name: replaceInput,
                              submit_btn: true,
                            });
                          }
                        }}
                      />
                    </div>
                    <div class="form-group">
                      <span class="group-name">นามสกุล</span>
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        class="form-control"
                        value={last_name}
                        onChange={(e) => {
                          let replaceInput = e.currentTarget.value.replace(
                            /[!$%^*()+></?=\;#]/g,
                            ""
                          );
                          if (
                            replaceInput !== "" &&
                            first_name.length > 0 &&
                            mobile.length === 10
                          ) {
                            this.setState({
                              last_name: replaceInput,
                              submit_btn: false,
                            });
                          } else {
                            this.setState({
                              last_name: replaceInput,
                              submit_btn: true,
                            });
                          }
                        }}
                      />
                    </div>
                    <div class="form-group last">
                      <span class="group-name">เบอร์โทรศัพท์</span>
                      <input
                        type="text"
                        name="mobile"
                        id="mobile"
                        maxLength="10"
                        className="form-control"
                        value={mobile}
                        onChange={(e) => {
                          let replaceInput = e.currentTarget.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          if (
                            last_name.length > 0 &&
                            first_name.length > 0 &&
                            replaceInput.length === 10
                          ) {
                            this.setState({
                              mobile: replaceInput,
                              submit_btn: false,
                            });
                          } else {
                            this.setState({
                              mobile: replaceInput,
                              submit_btn: true,
                            });
                          }
                        }}
                      />
                    </div>
                    <p>
                      <font>**</font>เพื่อยืนยันการใช้สิทธิประโยชน์
                      กรุณากรอกเบอร์โทรศัพท์
                    </p>
                  </div>

                  <div class="btncover">
                    <button
                      class="btn"
                      disabled={submit_btn}
                      //  disabled={false}
                      onClick={() => {
                        this.submitHome();
                      }}
                    >
                      ต่อไป
                    </button>
                  </div>
                </>
              ) : null}
              {page === "condition" ? (
                <>
                  <div class="condition">
                    <div class="condition_box">
                      <button
                        class="condition_close"
                        onClick={() => {
                          this.submitPreviewEdit();
                        }}
                      >
                        X
                      </button>
                      <h1>ข้อกำหนดและเงื่อนไข</h1>
                      <span>
                        พรีเมี่ยมโกลด์ เยาวราช จะข้อใช้ข้อมูลที่ระบุไว้
                        เพื่อวัตถุประสงค์ในการป้องกันการใช้งานที่ไม่เหมาะสม
                        รวมไปถึงการใช้บริการ การพัฒนานำเสนอโปรโมชั่น
                        การประชาสัมพันธ์
                        และการปรับปรุงการบริการเป็นการเก็บรวบรวมการใช้และเปิดเผยข้อมูลส่วนตัว
                        สำหรับข้อมูลต่างๆ ได้แก่ ชื่อ นามสกุล เบอร์โทรศัพท์
                        เพื่อตรวจสอบและยืนยันตัวตนในการสมัคร และ/หรือใช้บริการ
                        และหรือเป็นหลักฐานในทางกฎหมายสำหรับข้อต่อสู้ทางกฎหมาย
                        หรือเป็นการดำเนินการตามที่กฎหมายกำหนด
                      </span>
                      <span>
                        บริษัทฯ
                        จะไม่จัดสรรหรือขายข้อมูลส่วนบุคคลของท่านให้แก่บุคคลภายนอกอื่นเพื่อวัตถุประสงค์ในการนำไปใช้โดยอิสระ
                        นอกจากนี้บริษัทฯ
                        จะไม่เปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่องค์กรอื่นใด
                        เว้นแต่การเปิดเผยข้อมูลนั้นเป็นไปตามที่กฎหมายอนุญาต
                        หรือในกรณีที่บริษัทฯ
                        มีหน้าที่ตามกฎหมายกำหนดให้ต้องเปิดเผย,
                        หรือเป็นการเปิดเผยตามคำสั่งโดยชอบด้วยกฎหมายของศาลหรือพนักงานเจ้าหน้าที่ผู้มีอำนาจ
                      </span>

                      <b class="text-left">หมายเหตุ</b>
                      <ul>
                        <li>
                          เมื่อลงทะเบียนสำเร็จ
                          ระบบจะยืนยันให้ทราบทันทีว่าท่านได้ลงทะเบียนเรียบร้อยแล้ว
                        </li>
                        <li>
                          ท่านจะได้รับคูปองในไลน์ภายหลังจากการลงทะเบียนเสร็จสิ้น
                        </li>
                        <li>
                          คูปองที่ท่านได้รับจำนวนทั้งสิ้น 4 ใบ
                          ตรวจสอบเงื่อนไขการใช้คูปองในแต่ละสิทธิ์อีกครั้ง
                        </li>
                        <li>
                          กรณีมีปัญหาในการลงทะเบียน และข้อมูลเพิ่มเติม ติดต่อ
                          Call Center โทร.02-958-1355 ต่อ 1200,1202
                        </li>
                      </ul>
                      <b class="text-center">
                        ----------- รีไฟแนนซ์ รับอัตราดอกเบี้ย 0.99% -----------
                      </b>
                      <ul>
                        <li>
                          ลูกค้าย้ายทองคำ ได้รับสิทธิ์จ่ายดอกเบี้ย 0.99% จำนวน 2
                          เดือนแรก
                        </li>
                        <li>เดือนที่ 3 รับอัตราดอกเบี้ย 1.25% ตามปกติ</li>
                        <li>
                          E-COUPON ย้ายทอง 0.99% สามารถใช้สิทธิ์ได้ 1 สิทธิ์ / 1
                          ตั๋วสัญญาขายฝาก{" "}
                        </li>
                        <li>
                          สามารถใช้ E-COUPON ได้ที่พรีเมี่ยมโกลด์ เยาวราช
                          สาขาธัญบุรี คลอง6 เท่านั้น
                        </li>
                        <li>
                          กำหนดระยะเวลาการใช้สิทธิ์ หมดเขต 31 สิงหาคม 2564
                        </li>
                      </ul>

                      <b class="text-center">
                        --------- ขายทองคำ รับเพิ่มทันที บาทละ 100 บาท
                        ----------
                      </b>
                      <ul>
                        <li>
                          ลูกค้านำทองคำมาขาย ใช้ E-COUPON รับเพิ่มบาทละ 100 บาท
                        </li>
                        <li>
                          ลูกค้าสามารถใช้สิทธิ์ได้ 1 สิทธิ์ / 1 บิลรับซื้อ{" "}
                        </li>
                        <li>
                          สามารถใช้ E-COUPON ได้ที่พรีเมี่ยมโกลด์ เยาวราช
                          สาขาธัญบุรี คลอง6 เท่านั้น
                        </li>
                        <li>
                          กำหนดระยะเวลาในการใช้สิทธิ์ หมดเขต 31 สิงหาคม 2564
                        </li>
                      </ul>
                      <b class="text-center">
                        -------- ซื้อเครื่องประดับเพชร ลดราคาจากป้าย 15%
                        ---------
                      </b>
                      <ul>
                        <li>สำหรับการใช้สิทธิ์ซื้อสินค้า 1 สิทธิ์ / 1 ชิ้น</li>
                        <li>
                          สามารถใช้ E-COUPON ได้ที่พรีเมี่ยมโกลด์ เยาวราช
                          สาขาธัญบุรี คลอง6 เท่านั้น
                        </li>
                        <li>ชำระสินค้าเป็นเงินสดเท่านั้น</li>
                        <li>
                          กำหนดระยะเวลาในการใช้สิทธิ์ หมดเขต 31 สิงหาคม 2564
                        </li>
                      </ul>
                      <b class="text-center">
                        -------------------- ฟรีค่ากำเหน็จ ------------------
                      </b>
                      <ul>
                        <li>
                          สำหรับการใช้สิทธิ์ซื้อสินค้า 1 ครั้ง (จำกัดการซื้อ
                          ไม่เกิน 5 ชิ้น)
                        </li>
                        <li>
                          สามารถใช้ E-COUPON ได้ที่พรีเมี่ยมโกลด์ เยาวราช
                          สาขาธัญบุรี คลอง6 เท่านั้น
                        </li>
                        <li>ชำระสินค้าเป็นเงินสดเท่านั้น </li>
                        <li>
                          กำหนดระยะเวลาในการใช้สิทธิ์ หมดเขต 31 พฤษภาคม 2564
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="btncover">
                    <h5 class="text-center">ยอมรับเงื่อนไข</h5>
                    <button
                      class="btn"
                      onClick={() => {
                        // this.submitPreviewEdit();
                        this.submitPreviewNext();
                        // this.submitCondition();
                      }}
                    >
                      ยืนยันรับสิทธิ์
                    </button>
                  </div>
                </>
              ) : null}
              {page === "preview" ? (
                <>
                  <div class="datahead">
                    <h1>สิทธิพิเศษ</h1>
                    <h3>เฉพาะลูกค้าลงทะเบียนเท่านั้น</h3>
                  </div>
                  <div class="dataform">
                    <div class="form-group">
                      <div>{first_name}</div>
                    </div>
                    <div class="form-group">
                      <div>{last_name}</div>
                    </div>
                    <div class="form-group last">
                      <div>{mobile}</div>
                    </div>
                  </div>

                  <div class="appqr">
                    <img src={appqr} alt="" />
                  </div>
                  <div class="btncover">
                    <button
                      class="btn"
                      onClick={() => {
                        this.submitPreviewNext();
                      }}
                    >
                      ต่อไป
                    </button>
                    <div></div>
                    <button
                      class="btn sub"
                      onClick={() => {
                        this.submitPreviewEdit();
                      }}
                    >
                      แก้ไขข้อมูล
                    </button>
                  </div>
                </>
              ) : null}
              {page === "success" ? (
                <section class="success">
                  <div class="success_box">
                    <div class="success_title">
                      <b class="success_head">ยินดีด้วย</b>
                      <span class="success_text">
                        คุณได้รับสิทธิ์ จัดหนักรับ 4 เด้ง เรียบร้อยแล้ว
                      </span>
                    </div>
                    <img src={coupon} alt="" class="success_coupon" />

                    <div class="success_footer">
                      <span class="success_branch">
                        ห้างทองอันดับ 1 สาขาธัญบุรี คลอง6
                      </span>
                      <span class="success_opening">
                        เปิด 10 พฤษภาคม 64 นี้
                      </span>
                    </div>
                  </div>
                </section>
              ) : null}
            </section>
          </body>
        </>
      </Fragment>
    );
  }
}
// export default Home;
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Home);
