// import Provider from './globalService/Provider-Api';
import Provider from "../Global/Provider-Api";
import { apikey, min, max, channel } from "../Global/config";
import cryptojs from "crypto-js";
import colorLog from "../Global/debuglog";

class Home {
  ganeratesig = (action, module, method, condition, data) => {
    let nonce = this.nonceFunc();
    let sig = action + module + method + condition + data + nonce;
    var hash = cryptojs.HmacSHA256(sig, apikey);
    const encode = {
      nonce: nonce,
      encypted: hash,
    };
    return encode;
  };
  nonceFunc = () => {
    let microtime = Date.now() * 100;
    let rand =
      (Math.floor(Math.random() * max) + min).toString().length === 5
        ? Math.floor(Math.random() * max) + min
        : (Math.floor(Math.random() * max) + min).toString().substring(0, 5);
    if (rand.toString().length > 5) {
      let microData = microtime.toString() + "12345";
      return microData;
    } else {
      let microData = microtime.toString() + rand.toString();
      return microData;
    }
  };
  headerFunc = (jwt, jsondata) => {
    let hash = cryptojs.HmacSHA512(jwt + jsondata, apikey);
    return hash;
  };

  getProvider = (jwt, condition, field, module, method) => {
    colorLog(
      "-----------------------GET-Provider---------------------",
      "Orange",
      jwt
    );
    colorLog("TYPE:" + "POST", "Orange");
    colorLog("CONDITION:" + JSON.stringify(condition), "Orange");
    colorLog("FIELD:" + JSON.stringify(field), "Orange");
    colorLog("MODULE:" + JSON.stringify(module), "Orange");
    colorLog("METHOD:" + JSON.stringify(method), "Orange");
    let type = "GET";
    let json_condition = JSON.stringify(condition);
    let json_field = JSON.stringify(field);
    let encypted = this.ganeratesig(
      "get",
      module,
      method,
      json_condition,
      json_field
    );
    let get_url =
      "get/" +
      module +
      "/" +
      method +
      "/" +
      json_condition +
      "/" +
      json_field +
      "/" +
      encypted.nonce +
      "/" +
      encypted.encypted;
    let jsonbody = "";
    let signature = this.headerFunc(jwt, jsonbody);
    let option = {
      headers: {
        "X-Authorization": "Bearer " + jwt,
        "X-Signature": "" + signature,
        "Content-Type": "application/json",
      },
    };
    let data = field;
    return { type, get_url, option, data };
  };

  postProvider = (jwt, field, condition, module, method) => {
    colorLog(
      "-----------------------POST-Provider---------------------",
      "Orange"
    );
    colorLog("TYPE:" + "POST", "Orange");
    colorLog("CONDITION:" + JSON.stringify(condition), "Orange");
    colorLog("FIELD:" + JSON.stringify(field), "Orange");
    colorLog("MODULE:" + JSON.stringify(module), "Orange");
    colorLog("METHOD:" + JSON.stringify(method), "Orange");
    let type = "POST";
    let json_condition = "";
    let json_field = JSON.stringify(field);
    let encypted = this.ganeratesig(
      "post",
      module,
      method,
      json_condition,
      json_field
    );
    let get_url =
      "post/" +
      module +
      "/" +
      method +
      "/" +
      json_field +
      "/" +
      encypted.nonce +
      "/" +
      encypted.encypted;
    let signature = this.headerFunc(jwt, json_field);

    let option = {
      headers: {
        "X-Authorization": "Bearer " + jwt,
        "X-Signature": "" + signature,
        "Content-Type": "application/json",
      },
    };
    let data = json_field;
    return { type, get_url, option, data };
  };
  putProvider = (jwt, field, condition, module, method) => {
    colorLog(
      "-----------------------PUT-Provider---------------------",
      "Orange"
    );
    // colorLog('TYPE:' + 'PUT', 'Orange');
    // colorLog('CONDITION:' + JSON.stringify(condition), 'Orange');
    // colorLog('FIELD:' + JSON.stringify(field), 'Orange');
    // colorLog('MODULE:' + JSON.stringify(module), 'Orange');
    // colorLog('METHOD:' + JSON.stringify(method), 'Orange');
    // console.log(jwt);
    let type = "PUT";
    let json_condition = JSON.stringify(condition);
    let json_field = JSON.stringify(field);
    let encypted = this.ganeratesig(
      "put",
      module,
      method,
      json_condition,
      json_field
    );
    let get_url =
      "put/" +
      module +
      "/" +
      method +
      "/" +
      json_condition +
      "/" +
      json_field +
      "/" +
      encypted.nonce +
      "/" +
      encypted.encypted;
    let signature = this.headerFunc(jwt, json_field);
    let option = {
      headers: {
        "X-Authorization": "Bearer " + jwt,
        "X-Signature": "" + signature,
        "Content-Type": "application/json",
      },
    };
    let data = json_field;
    return { type, get_url, option, data };
  };

  // loadStartJWT
  async loadStartJWT() {
    let arrstart = { channel: channel };
    let jsoncdata = JSON.stringify(arrstart);
    let encypted = this.ganeratesig("start", "startprogram", "", jsoncdata, "");
    let get_url =
      "start/startprogram/" +
      jsoncdata +
      "/" +
      encypted.nonce +
      "/" +
      encypted.encypted;
    console.log("get_url", get_url);

    let dataRes = await Provider.api({
      type: "GET",
      url: get_url,
      data: {},
    });
    console.log(dataRes);

    colorLog("FROM API --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }
  async getDataCustomers(jwt) {
    let condition = {};
    let field = {};
    let module = "lineliff";
    let method = "getDataCustomers";
    const { type, get_url, option, data } = this.getProvider(
      jwt,
      condition,
      field,
      module,
      method
    );
    console.log(get_url);
    // console.log(get_url);
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }
  async getAllProvinces(jwt) {
    let condition = {};
    let field = {};
    let module = "shoppingcart";
    let method = "getAllProvinces";
    const { type, get_url, option, data } = this.getProvider(
      jwt,
      condition,
      field,
      module,
      method
    );
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    dataRes.data = dataRes.data.map((v) => {
      return {
        id: v.id,
        value: v.id,
        label: v.name_th,
      };
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }

  // getDistrictByProvinceID
  async getDistrictByProvinceID(jwt, id) {
    let condition = { province_id: id };
    let field = {};
    let module = "shoppingcart";
    let method = "getDistrictByProvinceID";
    const { type, get_url, option, data } = this.getProvider(
      jwt,
      condition,
      field,
      module,
      method
    );
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    dataRes.data = dataRes.data.map((v) => {
      return {
        id: v.id,
        value: v.id,
        label: v.name_th,
        name_en: v.name_en,
        code: v.code,
      };
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }
  // getSubDistrictByDistrictID
  async getSubDistrictByDistrictID(jwt, id) {
    let condition = { district_id: id };
    let field = {};
    let module = "shoppingcart";
    let method = "getSubDistrictByDistrictID";
    const { type, get_url, option, data } = this.getProvider(
      jwt,
      condition,
      field,
      module,
      method
    );
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    dataRes.data = dataRes.data.map((v) => {
      return {
        id: v.id,
        value: v.id,
        label: v.name_th,
        name_en: v.name_en,
        zip_code: v.zip_code,
      };
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }

  // insertAddressCustomer
  async insertAddressCustomer(
    key,
    first_name,
    last_name,
    phone_number,
    address,
    selectedProvince,
    selectedDistrict,
    selectedSubdistrict,
    post_code,
    remark
  ) {
    let condition = "";
    // console.log('address',address);

    let field = {
      address: address,
      sub_district_id: selectedSubdistrict,
      district_id: selectedDistrict,
      province_id: selectedSubdistrict,
      post_code: post_code,
      remark: "test",
      first_name: first_name,
      last_name: last_name,
      phone_number: phone_number,
    };
    // console.log("jwt", key);
    let module = "lineliff";
    let method = "insertAddressCustomer";
    const { type, get_url, option, data } = this.postProvider(
      key,
      field,
      condition,
      module,
      method
    );
    console.log(get_url);
    console.log("option5555", option);

    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }

  // insertDataCustomer
  async insertDataCustomer(
    jwt,
    first_name,
    last_name,
    phone_number,
    email,
    locations,
    promotion_code,
    master_campaign_id,
    obj_location_detail,
    otp_code,
    otp_ref_code,
    uidLine
  ) {
    let condition = "";
    let field = {
      jwt,
      first_name,
      last_name,
      phone_number,
      email,
      locations,
      promotion_code,
      master_campaign_id,
      obj_location_detail,
      otp_code,
      otp_ref_code,
      uid: uidLine,
    };
    console.log('field',field);
    let module = "lineliff";
    let method = "insertDataCustomer";
    const { type, get_url, option, data } = this.postProvider(
      jwt,
      field,
      condition,
      module,
      method
    );
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }
  // randomReward
  async randomReward(jwt) {
    let condition = "";
    let field = {};
    let module = "lineliff";
    let method = "randomReward";
    const { type, get_url, option, data } = this.postProvider(
      jwt,
      field,
      condition,
      module,
      method
    );
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }
  //  getAllProvinces
  async actionLoginLineliff(jwt, uidLine, master_campaign_id) {
    console.log("jwt------------->", jwt);
    console.log("uidLine------------->", uidLine);
    let condition = "";
    let field = {
      customer_id: uidLine,
      master_campaign_id: master_campaign_id,
    };
    // {"customer_id":"dfslfd7sf9sdfas0fawdas","master_campaign_id":"1"}
    let module = "lineliff";
    let method = "actionLoginLineliff";
    const { type, get_url, option, data } = this.postProvider(
      jwt,
      field,
      condition,
      module,
      method
    );
    console.log("get_url", get_url);
    console.log("option", option);

    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }

  // checkPromotionCode
  async checkPromotionCode(jwt, promotion_code, master_campaign_id) {
    let condition = {
      promotion_code: promotion_code,
      master_campaign_id: master_campaign_id,
    };
    let field = {};
    let module = "lineliff";
    let method = "checkPromotionCode";
    const { type, get_url, option, data } = this.getProvider(
      jwt,
      condition,
      field,
      module,
      method
    );
    console.log(get_url);
    // console.log(get_url);
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }

  // requestOTP
  async requestOTP(jwt, phone_number, master_campaign_id) {
    let condition = {
      phone_number: phone_number,
      master_campaign_id: master_campaign_id
    };
    let field = {};
    let module = "lineliff";
    let method = "requestOTP";
    const { type, get_url, option, data } = this.getProvider(
      jwt,
      condition,
      field,
      module,
      method
    );
    console.log(get_url);
    // console.log(get_url);
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }

  // savePolicyLineliff

  async savePolicyLineliff(jwt, policy_name) {
    let condition = "";
    let field = {
      policy_name: policy_name,
    };
    let module = "lineliff";
    let method = "savePolicyLineliff";
    const { type, get_url, option, data } = this.postProvider(
      jwt,
      field,
      condition,
      module,
      method
    );
    let dataRes = await Provider.api({
      type: type,
      url: get_url,
      headers: option,
      data,
    });
    colorLog("FROM APIs --> " + JSON.stringify(dataRes), "info");
    colorLog(
      "----------------------------END--------------------------",
      "Orange"
    );
    return dataRes;
  }
}

export default new Home();
